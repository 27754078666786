<template>
  <section class="home-categories">
    <div class="home-categories__container">
      <div
        v-for="(category, index) in categories"
        :key="index"
        class="home-categories__item"
      >
        <NuxtLink
          :to="
            getUserLanguage.urlPrefix +
            '/' +
            getCategoryByCode(category.code).slug
          "
        >
          <CategoryIcon :image="category.img" />
          <h4 class="home-categories__title">{{ _T(category.title) }}</h4>
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useCategoriesStore } from "~/store/categoriesStore";
import CategoryIcon from "~/modules/home/components/CategoryIcon.vue";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const categoriesStore = useCategoriesStore();
const { getCategoryByCode } = storeToRefs(categoriesStore);

const categories = [
  {
    img: "home-category-1",
    title: "@CCTV Cameras",
    code: "2712",
  },
  {
    img: "home-category-2",
    title: "@DVRs for video surveillance",
    code: "1269",
  },
  {
    img: "home-category-3",
    title: "@CCTV kits",
    code: "3251",
  },
  {
    img: "home-category-4",
    title: "@ACS",
    code: "7069",
  },
  {
    img: "home-category-5",
    title: "@network hardware",
    code: "12871",
  },
  {
    img: "home-category-6",
    title: "@Power supplies",
    code: "2657",
  },
];
</script>

<style lang="scss">
.home-categories {
  padding: 0 16px;

  &__container {
    @extend %width-main;
    @include flex-container(row, space-between);

    margin: auto;
    margin-bottom: 32px;

    @include bigMobile {
      @include flex-container(row, center, center);
      flex-wrap: wrap;
    }

    @include mobile {
      @include flex-container(row, space-between, center);
      margin-bottom: 24px;
    }
  }

  &__item {
    cursor: pointer;
    width: 184px;

    text-align: center;

    margin-bottom: 32px;

    &:hover {
      & .home-categories__icon-hover {
        margin: auto;
        display: block;
      }
      & .home-categories__icon {
        display: none;
      }

      & .home-categories__title {
        color: var(--color-primary-base);
      }
    }

    & a {
      height: 100%;

      display: block;
    }

    @include bigMobile {
      margin: 0 16px 32px 16px;
    }

    @include mobile {
      width: 164px;

      margin: 0;
      margin-bottom: 20px;
    }
  }

  &__icon {
    margin: auto;
  }

  &__icon-hover {
    display: none;
  }

  &__title {
    @include font(16, 22, 600);
    color: var(--color-ink-base);

    margin-top: 8px;
    margin-bottom: 4px;

    @include mobile {
      @include font(14, 20, 600);
    }
  }
}
</style>
