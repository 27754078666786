<template>
  <section class="home-fitters">
    <div class="home-fitters__container">
      <img
        src="~~/assets/icons/fitter.png"
        alt="fitter"
        class="home-fitters__picture"
      />
      <div class="home-fitters__content">
        <h4 class="home-fitters__title">{{ _T(fitters.title) }}</h4>
        <p class="home-fitters__title-description">
          {{ _T(fitters.subtitle) }}
        </p>
        <div
          v-for="(item, index) in fitters.items"
          :key="index"
          class="home-fitters__item"
        >
          <img
            class="home-fitters__svg"
            src="~~/assets/icons/fitter-arrow-icon.svg"
            alt="arrow"
          />
          <div>
            <h5 class="home-fitters__item-title">
              {{ _T(item.title) }}
            </h5>
            <p class="home-fitters__item-text">
              {{ _T(item.text) }}
            </p>
          </div>
        </div>
        <div class="home-fitters__btn-box">
          <button class="home-fitters__btn" @click="showModal">
            {{ _T(fitters.btn) }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import {
  installerSubscriptionModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const modalStore = useModalStore();

const successfulData = {
  title: _T("@Become an installer"),
  description: _T("@Thank you, our managers will contact you"),
  isWithTimeout: true,
};

function showModal() {
  modalStore.toggleModal(installerSubscriptionModal, successfulData);
}

const fitters = {
  title: "@CCTV installers",
  subtitle: "@Increase the number of loyal customers by installing",
  items: [
    {
      title:
        "@Preferential terms of purchase for installers of video surveillance systems",
      text: "@Flexible systems of discounts and bonuses for the purchase of our products.",
    },
    {
      title: "@Help from company specialists",
      text: "@From drawing up an estimate to putting the finished facility into operation",
    },
    {
      title: "@Free education",
      text: "@You can study the technical specifications",
    },
  ],
  btn: "@Become an installer",
};
</script>

<style lang="scss" scoped>
.home-fitters {
  padding: 0 16px;
  margin-bottom: 32px;

  @include mobile {
    margin-bottom: 24px;
  }

  &__container {
    @extend %width-main;
    margin: auto;

    @include flex-container(row, space-around, center);

    @include bigMobile {
      @include flex-container(column, center, center);
    }
  }

  &__picture {
    @include bigMobile {
      max-width: 400px;
    }

    @include mobile {
      max-width: 65%;
    }
  }

  &__content {
    max-width: 610px;
    width: 100%;

    padding-left: 20px;

    @include bigMobile {
      margin-top: 32px;
      padding-left: 0;
    }

    @include mobile {
      margin-top: 16px;
    }
  }

  &__title {
    @include font(28, 39, 600);

    margin-bottom: 16px;

    @include bigMobile {
      @include font(24, 34, 600);
    }
    @include mobile {
      margin-bottom: 8px;
      @include font(20, 30, 600);
    }
  }

  &__title-description {
    position: relative;

    @include font(18, 25, 400);

    padding-top: 16px;
    margin-bottom: 24px;

    &::before {
      content: "";
      height: 2px;
      width: 104px;

      position: absolute;
      top: 0;
      left: 0;

      background-color: var(--color-primary-base);
    }

    @include bigMobile {
      @include font(16, 22, 400);

      margin-bottom: 16px;
    }
  }

  &__item {
    @include flex-container(row, flex-start);

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &:last-of-type {
      margin-bottom: 24px;

      @include mobile {
        margin-bottom: 16px;
      }
    }
  }

  &__svg {
    max-width: 32px;
    width: 100%;
    height: 32px;

    margin-right: 12px;

    @include mobile {
      margin-right: 8px;
    }
  }

  &__item-title {
    @include font(18, 25, 600);
    margin-bottom: 8px;

    @include bigMobile {
      @include font(16, 22, 600);
    }
  }

  &__item-text {
    @include font(16, 22, 400);

    @include bigMobile {
      @include font(14, 20, 400);
    }
  }

  &__btn-box {
    @include bigMobile {
      text-align: center;
    }
  }

  &__btn {
    @include font(18, 24, 400);
    color: white;

    background-color: var(--color-primary-base);
    border: 2px solid var(--color-primary-base);
    border-radius: 8px;

    padding: 15px 89px;

    transition:
      color 0.4s ease-in-out,
      background-color 0.4s ease-in-out;

    &:hover {
      color: var(--color-primary-base);
      background-color: white;
    }

    @include mobile {
      max-width: 343px;
      width: 100%;

      padding: 14px 0px;
    }
  }

  &__link {
    padding: 21px 0;

    @include mobile {
      padding: 17px 0;
    }
  }
}
</style>
