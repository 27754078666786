<template>
  <section class="home-leave">
    <div class="home-leave__container">
      <img
        class="home-leave__picture-left"
        src="~/assets/icons/leave-contacts-camera.png"
        alt="camera"
      />
      <div class="home-leave__content-box">
        <div class="home-leave__content">
          <h4 class="home-leave__title">
            {{ _T("@To get a free consultation, fill out the form") }}
          </h4>
          <p class="home-leave__text">
            {{
              _T(
                "@The manager will contact you and answer all questions about choosing equipment",
              )
            }}
          </p>
        </div>
        <button class="home-leave__button" @click="showModal">
          <img
            class="home-leave__button-icon"
            src="~/assets/icons/notebook-icon.svg"
            alt="notebook"
          />
          {{ _T("@Leave contacts") }}
        </button>
      </div>

      <img
        class="home-leave__picture-right"
        src="~/assets/icons/leave-contacts-camera-right.png"
        alt="camera"
      />
    </div>
  </section>
</template>

<script setup>
import {
  installerSubscriptionModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";

const modalStore = useModalStore();

const successfulData = {
  title: _T("@Free consultation"),
  description: _T("@Thank you, our managers will contact you"),
  isWithTimeout: true,
};

function showModal() {
  modalStore.toggleModal(installerSubscriptionModal, successfulData);
}
</script>

<style lang="scss" scoped>
.home-leave {
  margin-bottom: 32px;
  padding: 0 16px;

  @include mobile {
    margin-bottom: 24px;
  }

  &__container {
    @extend %width-main;

    position: relative;
    @include flex-container(row, center, center);

    background: linear-gradient(
      135deg,
      rgba(2, 48, 39, 1),
      rgba(0, 155, 62, 1),
      rgba(80, 218, 160, 1)
    );
    border-radius: 8px;

    padding: 46px 12px;
    margin: auto;

    @include bigMobile {
      @include flex-container(column, center, center);
      padding: 0px 12px;
    }
  }

  &__content-box {
    @include flex-container(row, center, center);

    @include smallestScreen {
      @include flex-container(column, center, center);
    }
  }

  &__content {
    max-width: 440px;
    width: 100%;

    z-index: 10;

    color: white;

    @include smallestScreen {
      text-align: center;
      margin-bottom: 16px;
      max-width: 70%;
    }

    @include mobile {
      max-width: 100%;
    }
  }

  &__title {
    @include font(18, 24, 600);
    margin-bottom: 8px;
  }

  &__text {
    @include font(16, 24, 400);
  }

  &__button {
    z-index: 10;
    @include flex-container(row, center, center);

    @include font(18, 24, 400);
    color: var(--color-primary-base);
    background-color: white;
    border-radius: 8px;

    margin-left: 24px;
    padding: 8px 32px;

    @include smallestScreen {
      margin-left: 0;
    }
  }

  &__button-icon {
    margin-right: 10px;
  }

  &__picture-right {
    position: absolute;
    right: 0;
    bottom: 0;

    @include bigMobile {
      width: 250px;

      position: static;
      align-self: end;

      margin-top: -30px;
    }

    @include mobile {
      width: 214px;
      margin-top: 8px;
    }
  }

  &__picture-left {
    position: absolute;
    left: 0;
    bottom: 0px;

    @include smallestScreen {
      width: 230px;
    }

    @include bigMobile {
      width: 215px;

      position: static;
      align-self: start;

      margin-top: -90px;
    }

    @include mobile {
      width: 156px;
      height: 156px;

      margin-top: -52px;
    }
  }
}
</style>
