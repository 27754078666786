<template>
  <section class="home-feedback">
    <h2 class="home-feedback__title">
      {{ _T("@Still have questions?") }}
    </h2>
    <p class="home-feedback__description">
      {{ _T("@The GreenVision team employs highly qualified specialists") }}
    </p>
    <div class="home-feedback__container">
      <FeedbackForm />
      <div class="home-feedback__content">
        <h3 class="home-feedback__subtitle">
          {{ _T("@To get a free consultation, fill out the form") }}
        </h3>
        <p class="home-feedback__text">
          {{ _T("@The manager will contact you and answer all questions") }}
        </p>
      </div>
    </div>
  </section>
</template>

<script setup>
import FeedbackForm from "~/modules/shared/feedbackForm/FeedbackHomeForm.vue";
import { useLanguageFilterStore } from "~~/store/languageFilterStore";

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);
</script>

<style lang="scss" scoped>
.home-feedback {
  padding: 0 16px;
  margin-bottom: 32px;

  @include mobile {
    margin-bottom: 24px;
  }

  &__title {
    text-align: center;
    @include font(28, 39, 600);
    color: black;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(26, 34, 600);
    }

    @include mobile {
      @include font(22, 28, 600);
      margin-bottom: 8px;
    }
  }
  &__description {
    max-width: 1200px;

    @include font(22, 31, 500);
    color: var(--color-ink-base);
    text-align: center;

    margin: auto;
    margin-bottom: 16px;

    @include bigMobile {
      @include font(20, 27, 500);
    }

    @include mobile {
      @include font(18, 24, 500);
    }
  }

  &__container {
    max-width: 955px;
    width: 100%;

    @include flex-container(row, space-between, center);

    color: var(--color-ink-light);
    background-color: var(--color-sky-lighter);
    border-radius: 48px;

    margin: auto;
    padding: 48px 44px 48px 66px;

    @include mobile {
      @include flex-container(column, center, center);
      text-align: center;

      border-radius: 20px;
      padding: 16px;
    }
  }

  &__content {
    width: 380px;
    padding-left: 20px;

    @include mobile {
      width: 100%;

      padding: 0 6px;
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    @include font(20, 30, 700);
    margin-bottom: 25px;

    @include bigMobile {
      @include font(18, 28, 700);
    }

    @include mobile {
      @include font(16, 24, 700);
      margin-bottom: 15px;
    }
  }

  &__text {
    @include font(16, 22, 400);

    @include bigMobile {
      @include font(14, 20, 400);
    }

    @include mobile {
      @include font(12, 18, 400);
    }
  }
}
</style>
